export const environment = {
    production: false,
    AcademyCode: "FourthDimension",
    GRAPHQL_ENDPOINT: 'https://api-dev.eduraapp.net/graphql/',
    UPLOAD_ENDPOINT: 'https://api-dev.eduraapp.net/file/upload',
    IMAGE_ENDPOINT: 'https://edura-dev-storage.s3.eu-west-2.amazonaws.com',
    API_URL: 'https://api-dev.eduraapp.net/api',
    FAWRY_RETURN_URL: 'https://dev.eduraapp.net/dashboard/wallet',
    i18n: {
      defaultLanguage: {
        code: 'en',
        name: 'English',
        culture: 'en-US',
        dir: 'ltr',
      },
      availableLanguages: [
        {
          code: 'en',
          name: 'English',
          culture: 'en-US',
          dir: 'ltr',
        },
        {
          code: 'ar',
          name: 'العربية',
          culture: 'ar-EG',
          dir: 'rtl',
        },
      ],
    },
  };
  