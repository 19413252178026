import { Injectable } from '@angular/core';
import {
  AskSessionQuestionMutation,
  AskSessionQuestionMutationVariables,
  BooleanGraphTypeResponse,
  CreateSessionMutation,
  CreateSessionMutationVariables,
  GetOnlineSessionInfoTypeResponse,
  //OnlineSessionInfoQuery,
  //OnlineSessionInfoQueryVariables,
  RemoveSessionMutation,
  RemoveSessionMutationVariables,
  Session,
  SessionCriteria,
  SessionInput,
  SessionQuery,
  SessionQuestionCriteria,
  SessionQuestionInput,
  SessionQuestionsQuery,
  SessionQuestionsQueryVariables,
  SessionQuestionTypeListResponse,
  SessionQuestionTypeResponse,
  SessionRateInput,
  SessionRateTypeResponse,
  SessionsQuery,
  SessionsQueryVariables,
  SessionTypeListResponse,
  SessionTypeResponse,
  StringGraphTypeResponse,
  SubmitSessionRateMutation,
  SubmitSessionRateMutationVariables,
  UpdateSessionMutation,
  UpdateSessionMutationVariables,
  GenerateQrCodeMutation,
  GenerateQrCodeMutationVariables,
  SessionAttendanceCriteria,
  SessionAttendanceTypeResponse,
  SessionAttendance,
  SessionAttendanceTypeListResponse,
  ConfirmSessionAttendanceInput,
  Teacher,
  TeacherMutations,
  StartOnlineSession,
  GetOnlineSessionInfo,
  ActivityCriteria,
  AssignmentTypeListResponse,
  AssignmentsQuery,
  AssignmentsQueryVariables,
  UserActivitiesQuery,
  Assignment,
  UserActivityCriteria,
  UserActivityTypeListResponse,
  UserActivitiesQueryVariables,
} from '@generated-types';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import {
  ASK_SESSION_QUESTION,
  CREATE_SESSION,
  GENERATE_QR_CODE,
  REMOVE_SESSION,
  SUBMIT_SESSION_RATE,
  UPDATE_SESSION,
} from '@mutations';
import { first, map } from 'rxjs/operators';
import { GET_SESSIONS, GET_SESSION_QUESTIONS } from '@queries';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AuthService } from './auth.service';
import { GET_SESSION_ATTENDANCE_CODE } from 'src/app/common/graphql/query/session-attendance-code.graphql';
import { CONFIRM_TEACHER_STUDENT_ATTENDANCE } from 'src/app/common/graphql/mutation/confirm-teacherStudent-attendance.graphql';
import { GET_ONLINE_SESSION_INFO } from 'src/app/common/graphql/mutation/online-session-info.graphql';
import { GET_INFO_ONLINE_SESSION } from 'src/app/common/graphql/query/get-online-session-info.graphql';
import { response } from 'express';
import { GET_SESSIONS_ASSIGMENTS } from 'src/app/common/graphql/query/get_session_assigments.graphql';
import { GET_USER_ACTIVITIES } from 'src/app/common/graphql/query/get-user-activities.graphql';
import { GET_SESSIONSLIST } from 'src/app/common/graphql/query/sessions-list.graphql';

@Injectable({
  providedIn: 'root',
})
export class SessionsService implements Resolve<Observable<Session>> {
  public constructor(
    private readonly dataService: DataService,
    private readonly authService: AuthService
  ) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<Session> {
    return this.getSessions({
      Id: +route.params.id,
      StudentIdCourseGroup: this.authService.getUserState().StudentId
        ? this.authService.getUserState().StudentId
        : null,
      TeacherId: this.authService.getUserState().TeacherId
        ? this.authService.getUserState().TeacherId
        : null,
    }).pipe(
      map((response) => response.Result[0]),
      first()
    );
  }

  public getSessions(
    criteria: SessionCriteria
  ): Observable<SessionTypeListResponse> {
    return this.dataService
      .query<SessionsQuery, SessionsQueryVariables>(GET_SESSIONS, {
        criteria,
      })
      .pipe(map((response) => response.data.SessionQuery.Sessions));
  }

  
  public getSessionsList(
    criteria: SessionCriteria
  ): Observable<SessionTypeListResponse> {
    return this.dataService
      .query<SessionsQuery, SessionsQueryVariables>(GET_SESSIONSLIST, {
        criteria,
      })
      .pipe(map((response) => response.data.SessionQuery.Sessions));
  }

  

  public getSessionAttendanceCode(
    criteria: SessionAttendanceCriteria
  ): Observable<SessionAttendanceTypeListResponse> {
    return this.dataService
      .query(GET_SESSION_ATTENDANCE_CODE, {
        criteria,
      })
      .pipe(
        map((response) => {
          return response.data.SessionQuery.SessionAttendanceCode;
        })
      );
  }

  public confirmAttendStudent(
    model: ConfirmSessionAttendanceInput
  ): Observable<SessionAttendanceTypeResponse> {
    return this.dataService
      .mutate(CONFIRM_TEACHER_STUDENT_ATTENDANCE, { model })
      .pipe(map((response) => response.data.ConfirmStudentAttendance));
  }

  public getSessionQuestions(
    criteria: SessionQuestionCriteria
  ): Observable<SessionQuestionTypeListResponse> {
    return this.dataService
      .query<SessionQuestionsQuery, SessionQuestionsQueryVariables>(
        GET_SESSION_QUESTIONS,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.SessionQuery.SessionsQuestions));
  }

  public getOnlineSessionInfo(
    Id: number
  ): Observable<GetOnlineSessionInfoTypeResponse> {
    return this.dataService
      .mutate(GET_ONLINE_SESSION_INFO, {
        Id,
      })
      .pipe(map((response) => response.data.Session.StartOnlineSession));
  }

  getStudentSessionOnlineInfo(
    idd: number
  ): Observable<GetOnlineSessionInfoTypeResponse> {
    return this.dataService
      .query(GET_INFO_ONLINE_SESSION, {
        sessionId: idd,
      })
      .pipe(map((response) => response.data.SessionQuery.OnlineSessionInfo));
  }

  public askSessionQuestion(
    model: SessionQuestionInput
  ): Observable<SessionQuestionTypeResponse> {
    return this.dataService
      .mutate<AskSessionQuestionMutation, AskSessionQuestionMutationVariables>(
        ASK_SESSION_QUESTION,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Session.AskSessionQuestion));
  }

  public rateSession(
    model: SessionRateInput,
    criteria: SessionCriteria
  ): Observable<SessionRateTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        SubmitSessionRateMutation,
        SubmitSessionRateMutationVariables,
        SessionQuery,
        SessionsQueryVariables
      >(
        SUBMIT_SESSION_RATE,
        {
          model,
        },
        GET_SESSIONS,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.Session.SubmitSessionRate));
  }

  public createSession(model: SessionInput): Observable<SessionTypeResponse> {
    console.log('modal before request => ', model);
    return this.dataService
      .mutate<CreateSessionMutation, CreateSessionMutationVariables>(
        CREATE_SESSION,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Session.CreateSession));
  }

  public updateSession(model: SessionInput): Observable<SessionTypeResponse> {
    return this.dataService
      .mutate<UpdateSessionMutation, UpdateSessionMutationVariables>(
        UPDATE_SESSION,
        {
          model,
        }
      )
      .pipe(map((response) => response.data.Session.UpdateSession));
  }

  public removeSession(
    SessionId: number,
    criteria: SessionCriteria
  ): Observable<BooleanGraphTypeResponse> {
    return this.dataService
      .mutateWithRefetch<
        RemoveSessionMutation,
        RemoveSessionMutationVariables,
        SessionsQuery,
        SessionsQueryVariables
      >(
        REMOVE_SESSION,
        {
          SessionId,
        },
        GET_SESSIONS,
        { criteria }
      )
      .pipe(map((response) => response.data.Session.DeleteSession));
  }

  public getSessionAssigmrnt(
    criatria: ActivityCriteria
  ): Observable<AssignmentTypeListResponse> {
    return this.dataService
      .query(GET_SESSIONS_ASSIGMENTS, { criatria })
      .pipe(map((response) => response.data.ActivityQuery.Assignments));
  }

  public getUserActivities(
    criteria: UserActivityCriteria
  ): Observable<UserActivityTypeListResponse> {
    return this.dataService
      .query<UserActivitiesQuery, UserActivitiesQueryVariables>(
        GET_USER_ACTIVITIES,
        { criteria }
      )
      .pipe(map((response) => response.data.ActivityQuery.UserActivities));
  }

  public getSessionAssigment(
    criteria: ActivityCriteria
  ): Observable<Assignment[]> {
    return this.dataService
      .query<AssignmentsQuery, AssignmentsQueryVariables>(
        GET_SESSIONS_ASSIGMENTS,
        {
          criteria,
        }
      )
      .pipe(map((response) => response.data.ActivityQuery.Assignments.Result));
  }

  public generateQRCode(
    sessionId: number
  ): Observable<StringGraphTypeResponse> {
    return this.dataService
      .mutate<GenerateQrCodeMutation, GenerateQrCodeMutationVariables>(
        GENERATE_QR_CODE,
        {
          sessionId,
        }
      )
      .pipe(map((response) => response.data.Session.GenerateQRCode));
  }

  public validDate(postDate: Date, endDate: Date): boolean {
    const monthPostDate = postDate.getMonth();
    const monthEndDate = endDate.getMonth();
    const dayPostDate = postDate.getDate();
    const dayEndtDate = endDate.getDate();
    const yearPostDate = postDate.getFullYear();
    const yearEndDate = endDate.getFullYear();
    const hoursePostDate = postDate.getHours();
    const hourseEndDate = endDate.getHours();

    console.log(hoursePostDate, hourseEndDate);

    if (
      yearPostDate === yearEndDate &&
      monthPostDate === monthEndDate &&
      dayPostDate > dayEndtDate
    ) {
      return false;
    }

    if (
      yearPostDate === yearEndDate &&
      monthPostDate > monthEndDate &&
      dayPostDate > dayEndtDate
    ) {
      return false;
    }

    if (
      yearEndDate > yearPostDate &&
      (monthPostDate > monthEndDate || dayPostDate > dayEndtDate)
    ) {
      return true;
    }

    if (
      monthPostDate > monthEndDate ||
      dayPostDate > dayEndtDate ||
      yearPostDate > yearEndDate
    ) {
      return false;
    }

    if (
      monthPostDate === monthEndDate &&
      dayPostDate === dayEndtDate &&
      yearPostDate === yearEndDate &&
      (hoursePostDate === hourseEndDate || hoursePostDate > hourseEndDate)
    ) {
      return false;
    }
    return true;
  }

  public changeFormate(date: Date): string {
    console.log(
      date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate()
    );
    return (
      date.getFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getDate()
    );
  }
}
