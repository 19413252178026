import { Component, Input, OnInit } from '@angular/core';
import { FileType } from '@generated-types';
import { Lightbox, LightboxConfig, IAlbum } from 'ngx-lightbox';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent implements OnInit {
  @Input() public attacmentPath: string;
  @Input() public fileType: FileType;

  public readonly FILE_TYPE = FileType;

  public constructor(
    private readonly lightbox: Lightbox,
    private readonly lightboxConfig: LightboxConfig
  ) {}

  public ngOnInit(): void {}

  public openLightbox(): void {
    const lightboxAlbum: IAlbum[] = [
      {
        src: this.attacmentPath,
        caption: null,
        thumb: null,
      },
    ];

    this.lightboxConfig.showZoom = true;
    this.lightboxConfig.showRotation = true;
    this.lightboxConfig.centerVertically = true;
    this.lightboxConfig.fitImageInViewPort = true;
    this.lightboxConfig.enableTransition = true;

    this.lightbox.open(lightboxAlbum, 0);
  }
}
