import { SessionAttendance } from '@generated-types';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

export class BaseDataSource<D, C> {
  protected readonly dataSubject$ = new BehaviorSubject<D[]>([]);
  protected readonly totalCountSubject$ = new BehaviorSubject<number>(0);
  protected readonly criteriaSubject$ = new BehaviorSubject<C>(null);
  protected readonly destroy$ = new Subject<void>();

  public readonly totalCount$ = this.totalCountSubject$.asObservable();
  public readonly criteria$ = this.criteriaSubject$.asObservable();

  public connect(): Observable<D[]> {
    return this.dataSubject$.asObservable();
  }

  public disconnect(): void {
    this.dataSubject$.next([]);
    this.dataSubject$.complete();
    this.totalCountSubject$.complete();
  }

  public set criteria(criteria: C) {
    debugger;
    this.criteriaSubject$.next({
      ...this.criteriaSubject$.value,
      ...criteria,
    });
  }

  public get currentCriteria(): C {
    return this.criteriaSubject$.value;
  }
}
